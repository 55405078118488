import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, AppBar, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import { Fab } from "@material-ui/core";
import { apiPost, setLoggedInuser } from "../Api/apiCommon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Auth from "Auth/Auth";
import logo from "../Assets/img/sapphire-logo.png";
import SweetAlert from "react-bootstrap-sweetalert";

const auth = new Auth();

function SignUp(props) {
  const [message, setMessage] = useState("");

  const [isMsgShow, setIsMsgShow] = useState(false);
  const [MsgType, setMsgType] = useState("alert alert-success");
  const history = useHistory();
  const loading = useSelector((state) => state.loading);
  const [success, setSuccess] = useState(false);

  const [emailMssage, setEmailMssage] = useState("");
  const [emailErrorVisible, setEmailErrorVisible] = useState("none");
  const [passwordMssage, setpasswordMssage] = useState(false);
  const [passwordErrorVisible, setpasswordErrorVisible] = useState("none");
  const inputEmailRef = useRef(null);
  const inputPasswordRef = useRef(null);

  const onUserLogin = async (event) => {
    event.preventDefault();

    const email = inputEmailRef.current.value;

    // Client-side validation
    if (!email) {
      setEmailErrorVisible("block");
      setEmailMssage("Please enter an email.");
      return;
    }

    try {
      // API call for login
      const response = await apiPost("/forgot-password", {
        email: email,
      });

      // if (response) {
      //   if (response.data.message === "Success") {
      //     if (response.data.data.User) {
      //       setLoggedInuser(response.data.data.User, response.data.data.token);

      //       if (response.data.data.User.roleUID === "1") {
      //         history.push("/app/crm/user");
      //       } else if (response.data.data.User.businessUnitUID === "7") {
      //         history.push("/app/user/view-completed-reports");
      //       } else {
      //         let workflowUsers =
      //           response.data.data.User.get_work_flow_statge_user;
      //         let initiator = false;
      //         let approval = false;
      //         let dataEntry = false;
      //         workflowUsers.forEach((item, i) => {
      //           if (parseInt(item.stageNo) === 1) {
      //             initiator = true;
      //           }
      //           if (
      //             parseInt(item.stageNo) <
      //               parseInt(item.work_flow.noOfStage) - 1 &&
      //             parseInt(item.stageNo) !== 1
      //           ) {
      //             approval = true;
      //           }
      //           if (
      //             parseInt(item.stageNo) ===
      //             parseInt(item.work_flow.noOfStage) - 1
      //           ) {
      //             dataEntry = true;
      //           }
      //         });
      //         if (
      //           initiator === true &&
      //           approval !== true &&
      //           dataEntry !== true
      //         ) {
      //           history.push("/app/user/workflow");
      //         } else if (
      //           initiator !== true &&
      //           approval === true &&
      //           dataEntry !== true
      //         ) {
      //           history.push("/app/user/approvallist");
      //         } else if (
      //           initiator !== true &&
      //           approval !== true &&
      //           dataEntry === true
      //         ) {
      //           history.push("/app/user/dataentrylist");
      //         }
      //       }
      //     } else {
      //       setMsgType("alert alert-danger");
      //       setMessage(response.data.data.error);
      //       setIsMsgShow(true);
      //     }
      //   }
      // } else {
      // }
      if (response) {
        if (response.data.data.error === "Invalid email address") {
          setMessage(response.data.data.error);
          console.log(response.data.data);
        } else {
          setMessage(response.data.data.success);
          console.log(response.data.data.error);
          setIsMsgShow(true);
          setSuccess(true);
        }

        setMsgType("alert alert-danger");
        // setMessage(response.data.data.error);
        // setMessage(response.data.data.success);
        console.log(response.data.data.error);
        setIsMsgShow(true);
        setSuccess(true);
        // history.push("/signin");
      }
    } catch (error) {
      setMsgType("alert alert-danger");
      setMessage("Something went wrong, Please try again later");
      setIsMsgShow(true);

      console.error("Error during login:", error);
    }
  };

  const focusInput = (inputRef) => {
    // Access the input element using the ref
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onConfirm = () => {
    setSuccess(false);
    history.push("/signin");
    // history.push("/app/crmuser/costlist");
    // handleClicks();
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container">
              <div className="d-flex justify-content-between"></div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-12 col-md-12 col-lg-6 mx-auto">
                <div className="session-body text-center">
                  <div className="session-head mb-30">
                    <h2 className="font-weight-bold">
                      <a href="#" className="logo logo-admin">
                        <img src={logo} height="60" alt="logo" />
                      </a>
                    </h2>
                  </div>
                  {/* <div
                    className={MsgType}
                    id="successmsg"
                    style={{ display: isMsgShow ? "block" : "none" }}
                  >
                    {message}
                  </div> */}

                  <Form>
                    <FormGroup className="has-wrapper">
                      <Input
                        type="mail"
                        name="user-mail"
                        id="user-mail"
                        innerRef={inputEmailRef}
                        className="has-input input-lg"
                        placeholder="Enter Email Address"
                        onChange={() => focusInput(inputEmailRef)}
                        required
                      />
                      <span className="has-icon">
                        <i className="ti-email"></i>
                      </span>
                      <div
                        className="inputImportantRed"
                        id="emailprint-error-msg-red"
                        style={{ display: emailErrorVisible, color: "red" }}
                      >
                        {emailMssage}
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-15">
                      <Button
                        id="submitBtn"
                        color="primary"
                        className="btn-warning btn-block text-white w-100 font-weight-bold"
                        variant="contained"
                        size="large"
                        onClick={onUserLogin}
                      >
                        Reset Password
                      </Button>
                    </FormGroup>
                    <Link to="/signin" className=" text-black w-100  mb-15">
                      Back to Login
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {message === "Invalid email address" ? (
        <SweetAlert
          // danger
          show={success}
          // title="error"
          btnSize="sm"
          onConfirm={onConfirm}
        >
          {message}
        </SweetAlert>
      ) : (
        <SweetAlert
          // success
          show={success}
          // title="Success"
          btnSize="sm"
          onConfirm={onConfirm}
        >
          {message}
        </SweetAlert>
      )}
    </QueueAnim>
  );
}

export default SignUp;
