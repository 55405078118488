import React from "react";
import RctDefaultLayout from "../../Container/DefaultLayout";
import CRMLayoutUser from "Container/CRMLayoutUser";

const UserComponent = () => {
  return (
    <>
      <>
        <CRMLayoutUser />
      </>
    </>
  );
};

export default UserComponent;
