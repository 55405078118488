module.exports = {
  "sidebar.app": "Aplicación",
  "sidebar.horizontal": "Horizontal",
  "sidebar.horizontalMenu": "Menú horizontal",
  // "sidebar.general": "General",
  "sidebar.component": "Componente",
  "sidebar.features": "Caracteristicas",
  "sidebar.applications": "Aplicaciones",
  "sidebar.dashboard": "Tablero",
  "sidebar.dashboard1": "Tablero de instrumentos 1",
  "sidebar.dashboard2": "Tablero 2",
  "sidebar.dashboard3": "Tablero 3",
  "sidebar.modules": "Módulos",
  "sidebar.agency": "Agencia",
  "sidebar.pages": "Páginas",
  "sidebar.gallery": "Galería",
  "sidebar.pricing": "Precios",
  "sidebar.terms&Conditions": "Términos y condiciones",
  "sidebar.feedback": "Realimentación",
  "sidebar.report": "Informe",
  "sidebar.faq(s)": "Faq(s)",
  "sidebar.advancedComponent": "Componente avanzado",
  "sidebar.blank": "Blanco",
  "sidebar.session": "Sesión",
  "sidebar.login": "Iniciar sesión",
  "sidebar.register": "Registro",
  "sidebar.lockScreen": "Bloquear pantalla",
  "sidebar.forgotPassword": "Olvidé mi contraseña",
  "sidebar.404": "404",
  "sidebar.500": "500",
  "sidebar.uiComponents": "Componentes de interfaz de usuario",
  "sidebar.alerts": "Alertas",
  "sidebar.appBar": "Barra de aplicaciones",
  "sidebar.avatars": "Avatares",
  "sidebar.buttons": "Botones",
  "sidebar.bottomNavigations": "Navegaciones inferiores",
  "sidebar.badges": "Insignias",
  "sidebar.cards": "Tarjetas",
  "sidebar.cardsMasonry": "Tarjetas Masonary",
  "sidebar.chip": "Chip",
  "sidebar.dialog": "Diálogo",
  "sidebar.dividers": "Divisores",
  "sidebar.drawers": "Cajones",
  "sidebar.popover": "Popover",
  "sidebar.expansionPanel": "Panel de Expansión",
  "sidebar.gridList": "Lista de cuadrícula",
  "sidebar.list": "Lista",
  "sidebar.menu": "Menú",
  "sidebar.popoverAndToolTip": "Popover & Consejo de herramienta",
  "sidebar.progress": "Progreso",
  "sidebar.snackbar": "Snackbar",
  "sidebar.selectionControls": "Controles de selección",
  "sidebar.advanceUiComponents": "Componentes avanzados de UI",
  "sidebar.dateAndTimePicker": "Selector de fecha y hora",
  "sidebar.tabs": "Pestañas",
  "sidebar.stepper": "Paso a paso",
  "sidebar.notification": "Notificación",
  "sidebar.sweetAlert": "Dulce Alerta",
  "sidebar.autoComplete": "Auto completo",
  "sidebar.aboutUs": "Sobre nosotros",
  "sidebar.widgets": "Widgets",
  "sidebar.forms": "Formularios",
  "sidebar.formElements": "Elementos de forma",
  "sidebar.textField": "Campo de texto",
  "sidebar.selectList": "Seleccionar lista",
  "sidebar.charts": "Gráficos",
  "sidebar.reCharts": "Re Gráficos",
  "sidebar.reactChartjs2": "Reaccionar Chartjs 2",
  "sidebar.icons": "Iconos",
  "sidebar.themifyIcons": "Themify Iconos",
  "sidebar.simpleLineIcons": "Iconos de línea sencillo",
  "sidebar.materialIcons": "Iconos de material",
  "sidebar.fontAwesome": "Fuente impresionante",
  "sidebar.tables": "Mesas",
  "sidebar.basic": "BASIC",
  "sidebar.dataTable": "Tabla de datos",
  "sidebar.responsive": "Sensible",
  "sidebar.reactTable": "Reaccionar",
  "sidebar.maps": " Mapas",
  "sidebar.googleMaps": "mapas de Google",
  "sidebar.leafletMaps": "Mapas de folletos",
  "sidebar.inbox": "Bandeja de entrada",
  "sidebar.users": "Usuarios",
  "sidebar.userProfile1": "Perfil de usuario 1",
  "sidebar.userProfile2": "Perfil de usuario 2",
  "sidebar.userManagement": "Gestión de usuarios",
  "sidebar.userProfile": "Perfil del usuario",
  "sidebar.userList": "Lista de usuarios",
  "sidebar.calendar": "Calendario",
  "sidebar.cultures": "Culturas",
  "sidebar.dnd": "Dnd",
  "sidebar.selectable": "Seleccionable",
  "sidebar.customRendering": "Representación personalizada",
  "sidebar.chat": "Charla",
  "sidebar.toDo": "Que hacer",
  "sidebar.editor": "Editor",
  "sidebar.wysiwygEditor": "Editor WYSIWYGWYSIWYG Editor",
  "sidebar.quillEditor": "Pluma Editor",
  "sidebar.reactAce": "Reaccionar Ace",
  "sidebar.dragAndDrop": "Arrastrar y soltar",
  "sidebar.reactDragula": "Reaccionar  Dragula",
  "sidebar.reactDnd": "Reaccionar Dnd",
  "sidebar.blogManagement": "Gestión de Blog",
  "sidebar.ecommerce": "Comercio electrónico",
  "sidebar.shopList": "Lista de compras",
  "sidebar.shopGrid": "Comprar cuadrícula",
  "sidebar.invoice": "Factura",
  "sidebar.multilevel": "Multinivel",
  "sidebar.sublevel": "Subnivel",
  "widgets.totalEarns": "Total gana",
  "widgets.emailsStatistics": "Estadísticas de correos electrónicos",
  "widgets.totalRevenue": "Los ingresos totales",
  "widgets.onlineVistors": "Votos en línea",
  "widgets.trafficSources": "Fuentes de tráfico",
  "widgets.RecentOrders": "órdenes recientes",
  "widgets.topSellings": "Mejores ventas",
  "widgets.productReports": "Informes de productos",
  "widgets.productStats": "Estadísticas del producto",
  "widgets.ComposeEmail": "Escribir correo",
  "widgets.employeePayroll": "Nómina de empleados",
  "widgets.visitors": "Visitantes",
  "widgets.orders": "Pedidos",
  "widgets.orderStatus": "Estado del pedido",
  "widgets.totalSales": "Ventas totales",
  "widgets.netProfit": "Beneficio neto",
  "widgets.overallTrafficStatus": "Estado general del tráfico",
  "widgets.tax": "Impuesto",
  "widgets.expenses": "Gastos",
  "widgets.currentTime": "Tiempo actual",
  "widgets.ratings": "Calificaciones",
  "widgets.currentDate": "Fecha actual",
  "widgets.todayOrders": "Pedidos de hoy",
  "widgets.toDoList": "Para hacer listas",
  "widgets.discoverPeople": "Descubre personas",
  "widgets.commments": "Comentarios",
  "widgets.newCustomers": "nuevos clientes",
  "widgets.recentNotifications": "Notificaciones recientes",
  "widgets.appNotifications": "Notificaciones de aplicación",
  "widgets.newEmails": "Nuevos correos electrónicos",
  "widgets.siteVisitors": "Visitantes del sitio",
  "widgets.recentActivities": "Actividades recientes",
  "widgets.recentOrders": "órdenes recientes",
  "widgets.gallery": "Galería",
  "widgets.pricing": "Precios",
  "widgets.enterpriseEdition": "Edición de Empresa",
  "widgets.personalEdition": "Edición personal",
  "widgets.socialCompanines": "Empresas sociales",
  "widgets.teamEdition": "Edición de equipo",
  "widgets.standard": "Estándar",
  "widgets.advanced": "Avanzado",
  "widgets.master": "MasterDominar",
  "widgets.Mega": "MegaMega",
  "widgets.logIn": "Iniciar sesión",
  "widgets.signUp": "Regístrate",
  "widgets.lockScreen": "Bloquear pantalla",
  "widgets.alertsWithLink": "Alertas con enlace",
  "widgets.additionalContent": "Contenido adicional",
  "widgets.alertDismiss": "Alerta de descartar",
  "widgets.uncontrolledDisableAlerts": "Alertas de Deshabilitar descontrolado",
  "widgets.contexualAlerts": "Alertas Contexuales",
  "widgets.alertsWithIcons": "Alertas con iconos",
  "widgets.Simple App Bars": "Barras de aplicación simples",
  "widgets.appBarsWithButtons": "Barras de aplicación con botones",
  "widgets.imageAvatars": "Avatares de imagen",
  "widgets.lettersAvatars": "Cartas Avatares",
  "widgets.iconsAvatars": "Iconos Avatares",
  "widgets.flatButtons": "Botones planos",
  "widgets.raisedButton": "Botón elevado",
  "widgets.buttonWithIconAndLabel": "Botón con icono y etiqueta",
  "widgets.floatingActionButtons": "Botones de acción flotante",
  "widgets.iconButton": "Botón de icono",
  "widgets.socialMediaButton": "Botón de redes sociales",
  "widgets.reactButton": "React Button",
  "widgets.buttonOutline": "Contorno del botón",
  "widgets.buttonSize": "Tamaño del botón",
  "widgets.buttonState": "Estado del botón",
  "widgets.buttonNavigationWithNoLabel": "botón Navegación sin etiqueta",
  "widgets.buttonNavigation": "Navegación de botón",
  "widgets.iconNavigation": "Navegación de iconos",
  "widgets.badgeWithHeadings": "Insignia con títulos",
  "widgets.contexualVariations": "Variaciones Contexuales",
  "widgets.badgeLinks": "Enlaces de placa",
  "widgets.materialBadge": "Insignia de material",
  "widgets.simpleCards": "Tarjetas simples",
  "widgets.backgroundVarient": "Variente de fondo",
  "widgets.cardOutline": "Esquema de la tarjeta",
  "widgets.overlayCard": "Tarjeta de superposición",
  "widgets.cardGroup": "Grupo de tarjetas",
  "widgets.cardTitle": "Título de la tarjeta",
  "widgets.speacialTitleTreatment": "Tratamiento de título especial",
  "widgets.chipWithClickEvent": "Chip con evento Click",
  "widgets.chipArray": "Chip Array",
  "widgets.dialogs": "Diálogos",
  "widgets.listDividers": "List Dividers",
  "widgets.insetDividers": "Divisores de inserción",
  "widgets.temporaryDrawers": "Cajones temporales",
  "widgets.permanentDrawers": "Cajones permanentes",
  "widgets.simpleExpansionPanel": "Panel de expansión simple",
  "widgets.controlledAccordion": "Acordeón controlado",
  "widgets.secondaryHeadingAndColumns": "Encabezado secundario y columnas",
  "widgets.imageOnlyGridLists": "Las listas de cuadrícula solo con imágenes",
  "widgets.advancedGridLists": "Listas de grillas avanzadas",
  "widgets.singleLineGridLists": "Listas de cuadrículas unifilares",
  "widgets.simpleLists": "Listas simples",
  "widgets.folderLists": "Listas de carpetas",
  "widgets.listItemWithImage": "Artículo de lista con imagen",
  "widgets.switchLists": "Cambiar listas",
  "widgets.insetLists": "Listas de inserción",
  "widgets.nestedLists": "Listas anidadas",
  "widgets.checkboxListControl": "Control de lista de casillas de verificación",
  "widgets.pinedSubHeader": "Sub encabezado pined",
  "widgets.InteractiveLists": "Listas interactivas",
  "widgets.simpleMenus": "Menús simples",
  "widgets.selectedMenu": "Menú Seleccionado",
  "widgets.maxHeightMenu": "Menú de altura máxima",
  "widgets.changeTransition": "Cambiar la transición",
  "widgets.paper": "Papel",
  "widgets.anchorPlayGround": "ancla Patio de recreo",
  "widgets.tooltip": "Información sobre herramientas",
  "widgets.positionedToolTips": "Barra de Snack Posicionado",
  "widgets.circularProgressBottomStart": "Inicio inferior de progreso circular",
  "widgets.interactiveIntegration": "Integración Interactiva",
  "widgets.determinate": "Determinado",
  "widgets.linearProgressLineBar": "Lineal Progreso Línea Bar",
  "widgets.indeterminate": "Indeterminado",
  "widgets.buffer": "Buffer",
  "widgets.query": "Consulta",
  "widgets.transitionControlDirection": "Dirección de control de transición",
  "widgets.simpleSnackbar": "Snackbar simple",
  "widgets.positionedSnackbar": "Barra de Snack Posicionado",
  "widgets.contexualColoredSnackbars": "Snackbars de colores contextuales",
  "widgets.simpleCheckbox": "Casilla de verificación simple",
  "widgets.interminateSelection": "Selección Interminada",
  "widgets.disabledCheckbox": "Casilla de verificación desactivada",
  "widgets.customColorCheckbox":
    "Casilla de verificación de color personalizado",
  "widgets.VerticalStyleCheckbox": "Casilla de verificación de estilo vertical",
  "widgets.horizontalStyleCheckbox":
    "Casilla de verificación de estilo horizontal",
  "widgets.radioButtons": "Botones de radio",
  "widgets.disabledRadio": "Radio inhabilitada",
  "widgets.withError": "Con error",
  "widgets.switches": "Swiches",
  "widgets.dateAndTimePicker": "Selector de fecha y hora",
  "widgets.defaultPicker": "Selector predeterminado",
  "widgets.timePicker": "Selector de tiempo",
  "widgets.weekPicker": "Selector de semana",
  "widgets.defaultDatePicker": "Selector de fecha predeterminado",
  "widgets.customPicker": "Selector personalizado",
  "widgets.tabs": "Pestañas",
  "widgets.fixedTabs": "Pestañas fijas",
  "widgets.basicTab": "Pestaña básica",
  "widgets.wrappedLabels": "Etiquetas envueltas",
  "widgets.centeredLabels": "Etiquetas centradas",
  "widgets.forcedScrolledButtons": "Botones desplazados forzados",
  "widgets.iconsTabs": "Pestañas de iconos",
  "widgets.withDisableTabs": "Con Inhabilitar Pestañas",
  "widgets.iconWithLabel": "Icono con etiqueta",
  "widgets.stepper": "Paso a paso",
  "widgets.horizontalLinear": "Horizontal lineal",
  "widgets.horizontalNonLinear": "Horizontal no lineal",
  "widgets.horizontalLinerAlternativeLabel":
    "Etiqueta alternativa horizontal del trazador de líneas",
  "widgets.horizontalNonLinerAlternativeLabel":
    "Etiqueta alternativa horizontal sin revestimiento",
  "widgets.verticalStepper": "Stepper vertical",
  "widgets.descriptionAlert": "Descripción Alerta",
  "widgets.customIconAlert": "Alerta de icono personalizado",
  "widgets.withHtmlAlert": "Con Html Alert",
  "widgets.promptAlert": "Aviso rápido",
  "widgets.passwordPromptAlert": "Alerta de solicitud de contraseña",
  "widgets.customStyleAlert": "Alerta de estilo personalizado",
  "widgets.autoComplete": "Auto completo",
  "widgets.reactSelect": "React Select",
  "widgets.downshiftAutoComplete": "Cambio descendente Auto Completar",
  "widgets.reactAutoSuggests": "Reaccionar Auto Sugiere",
  "widgets.aboutUs": "Sobre nosotros",
  "widgets.ourVission": "Nuestra Vision",
  "widgets.ourMissions": "Nuestras Misiones",
  "widgets.ourMotivation": "Nuestra motivación",
  "widgets.defualtReactForm": "Formulario de declaración de defualt",
  "widgets.url": "Url",
  "widgets.textArea": "Área de texto",
  "widgets.file": "Archivo",
  "widgets.formGrid": "Formulario Cuadrícula",
  "widgets.inlineForm": "Formulario en línea",
  "widgets.inputSizing": "Tamaño de entrada",
  "widgets.inputGridSizing": "Tamaño de cuadrícula de entrada",
  "widgets.hiddenLabels": "Etiquetas escondidas",
  "widgets.formValidation": "Formar Validación",
  "widgets.number": "Número",
  "widgets.date": "Fecha",
  "widgets.time": "Hora",
  "widgets.color": "Color",
  "widgets.search": "Buscar",
  "widgets.selectMultiple": "Seleccionar múltiples",
  "widgets.inputWithSuccess": "Entrada con éxito",
  "widgets.inputWithDanger": "Entrada con peligro",
  "widgets.simpleTextField": "Campo de texto simple",
  "widgets.componet": "Componentes",
  "widgets.layouts": "Diseños",
  "widgets.inputAdorements": "Adoración de entrada",
  "widgets.formattedInputs": "Entradas formateadas",
  "widgets.simpleSelect": "Simple Select",
  "widgets.nativeSelect": "Selección nativa",
  "widgets.MutltiSelectList": "Lista de selección Mutlti",
  "widgets.lineChart": "Gráfico de linea",
  "widgets.barChart": "Gráfico de barras",
  "widgets.stackedBarChart": "Gráfico de barras apiladas",
  "widgets.lineBarAreaChart": "Gráfico de área de la barra de líneas",
  "widgets.areaChart": "Carta de área",
  "widgets.stackedAreaChart": "Tabla de áreas apiladas",
  "widgets.verticalChart": "Gráfico vertical",
  "widgets.radarChart": "Gráfico de radar",
  "widgets.doughnut": "Rosquilla",
  "widgets.polarChart": "Gráfico polar",
  "widgets.pieChart": "Gráfico circular",
  "widgets.bubbleChart": "Bubble Gráfico",
  "widgets.horizontalBar": "Barra Horizonatl",
  "widgets.basicTable": "Tabla básica",
  "widgets.contexualColoredTable": "Tabla de colores Contexual",
  "widgets.dataTable": "Tabla de datos",
  "widgets.employeeList": "Lista de empleados",
  "widgets.responsiveTable": "Tabla receptiva",
  "widgets.responsiveFlipTable": "Mesa giratoria receptiva",
  "widgets.reactGridControlledStateMode":
    "Reaccionar Cuadrícula Revisado Estado Modo",
  "widgets.googleMaps": "mapas de Google",
  "widgets.productsReports": "Informes de productos",
  "widgets.taskList": "Lista de tareas",
  "widgets.basicCalender": "Calendario básico",
  "widgets.culturesCalender": "Calendario de las culturas",
  "widgets.dragAndDropCalender": "Calendario de arrastrar y soltar",
  "widgets.selectableCalender": "Calendario seleccionable",
  "widgets.customRendering": "Representación personalizada",
  "widgets.customCalender": "Calendario personalizado",
  "widgets.searchMailList": "Buscar lista de correo",
  "components.buyNow": "Compra ahora",
  "compenets.choose": "Escoger",
  "compenets.username": "Nombre de usuario",
  "compenets.passwords": "Contraseñas",
  "widgets.forgetPassword": "Contraseña olvidada",
  "compenets.signIn": "Registrarse",
  "compenets.dontHaveAccountSignUp": "No tiene registro de cuenta",
  "compenets.enterUserName": "Introduzca su nombre de usuario",
  "compenets.enterEmailAddress":
    "Introducir la dirección de correo electrónico",
  "compenets.confirmPasswords": "Confirmar contraseñas",
  "components.alreadyHavingAccountSignIn": "Ya tienes cuenta Inicia sesión",
  "components.enterYourPassword": "Ingresa tu contraseña",
  "components.unlock": "desbloquear",
  "components.enterPasswords": "Ingrese contraseñas",
  "components.resetPassword": "Rescatar contraseña",
  "components.pageNotfound": "Página no encontrada",
  "components.goToHomePage": "Ir a la página de inicio",
  "components.sorryServerGoesWrong": "Lo sentimos Servidor va mal",
  "components.persistentDrawer": "Cajón persistente",
  "components.back": "Espalda",
  "components.next": "Siguiente",
  "components.completeStep": "Paso completo",
  "components.withHtml": "Con Html",
  "components.prompt": "Rápido",
  "components.withDescription": "Con descripción",
  "components.success": "Éxito",
  "components.passwordPrompt": "Indicación de contraseña",
  "components.warning": "Advertencia",
  "components.customIcon": "Icono personalizado",
  "components.customStyle": "Estilo personalizado",
  "components.basic": "BASIC",
  "components.submit": "Enviar",
  "components.compose": "Componer",
  "components.sendMessage": "Enviar mensaje",
  "components.addNewTasks": "Agregar nuevas tareas",
  "components.addToCart": "Añadir a la cesta",
  "components.payNow": "Pague ahora",
  "components.print": "Impresión",
  "components.cart": "Carro",
  "components.viewCart": "Ver carro",
  "components.checkout": "Revisa",
  "widgets.QuickLinks": "enlaces rápidos",
  "widgets.upgrade": "mejorar",
  "widgets.app": "Aplicación",
  "widgets.addNew": "Agregar nuevo",
  "widgets.orderDate": "Fecha de orden",
  "widgets.status": "Estado",
  "widgets.trackingNumber": "El número de rastreo",
  "widgets.action": "Acción",
  "widgets.designation": "Designacion",
  "widgets.subject": "tema",
  "widgets.send": "Enviar",
  "widgets.saveAsDrafts": "Guardar como borradores",
  "widgets.onlineSources": "Fuentes en línea",
  "widgets.lastMonth": "El mes pasado",
  "widgets.widgets": "Widgets",
  "widgets.listing": "Listado",
  "widgets.paid": "Pagado",
  "widgets.refunded": "Reintegrado",
  "widgets.done": "Hecho",
  "widgets.pending": "Pendiente",
  "widgets.cancelled": "Cancelado",
  "widgets.approve": "Aprobar",
  "widgets.following": "Siguiendo",
  "widgets.follow": "seguir",
  "widgets.graphs&Charts": "Gráficos y gráficos",
  "widgets.open": "Abierto",
  "widgets.bounced": "Rebotado",
  "widgets.spam": "Correo no deseado",
  "widgets.unset": "Desarmado",
  "widgets.bandwidthUse": "Uso del ancho de banda",
  "widgets.dataUse": "Uso de datos",
  "widgets.unsubscribe": "Darse de baja",
  "widgets.profile": "Perfil",
  "widgets.messages": "Mensajes",
  "widgets.support": "Apoyo",
  "widgets.faq(s)": "Faq (s)",
  "widgets.upgradePlains": "Actualizar llanuras",
  "widgets.logOut": "Cerrar sesión",
  "widgets.mail": "Correo",
  "widgets.adminTheme": "Tema de Admin",
  "widgets.wordpressTheme": "Tema de Wordpress",
  "widgets.addToCart": "Añadir a la cesta",
  "widgets.plan": "Plan",
  "widgets.basic": "BASIC",
  "widgets.pro": "Pro",
  "widgets.startToBasic": "Comience a básico",
  "widgets.upgradeToPro": "Actualizar a Pro",
  "widgets.upgradeToAdvance": "Mejorar A Avanzar",
  "widgets.comparePlans": "Comparar Planes",
  "widgets.free": "Gratis",
  "widgets.frequentlyAskedQuestions": "Frecuentemente Preguntó Preguntas",
  "widgets.searchIdeas": "Buscar Ideas",
  "widgets.startDate": "comienzo Fecha",
  "widgets.endDate": "Fin Fecha",
  "widgets.category": "Categoría",
  "widgets.apply": "Apply",
  "widgets.downloadPdfReport": "Descargar Pdf Report",
  "widgets.yesterday": "Ayer",
  "widgets.totalOrders": "Total de pedidos",
  "widgets.totalVisitors": "Visitantes totales",
  "widgets.typeYourQuestions": "Escriba sus preguntas",
  "widgets.username": "Nombre de usuario",
  "widgets.password": "Contraseña",
  "widgets.signIn": "Registrarse",
  "widgets.enterYourPassword": "Ingresa tu contraseña",
  "widgets.alreadyHavingAccountLogin": "Ya tienes acceso a la cuenta",
  "widgets.composeMail": "Redactar correo",
  "widgets.issue": "Problema",
  "widgets.recentChat": "Charla reciente",
  "widgets.previousChat": "Charla anterior",
  "widgets.all": "Todas",
  "widgets.filters": "Filtros",
  "widgets.deleted": "Eliminado",
  "widgets.starred": "Sembrado de estrellas",
  "widgets.frontend": "Interfaz",
  "widgets.backend": "Backend",
  "widgets.api": "Api",
  "widgets.simpleAppBar": "Barra de aplicaciones simple",
  "widgets.recents": "Recientes",
  "widgets.cardLink": "Enlace de tarjeta",
  "widgets.anotherLink": "Otro enlace",
  "widgets.cardSubtitle": "tarjetaSubtittle",
  "widgets.confirmationDialogs": "Diálogos de confirmación",
  "widgets.deletableChip": "Chip eliminable",
  "widgets.customDeleteIconChip": "Chip de icono de eliminación personalizada",
  "widgets.openAlertDialog": "Diálogo de alerta abierta",
  "widgets.openResponsiveDialog": "Diálogo receptivo abierto",
  "widgets.openSimpleDialog": "Abrir un cuadro de diálogo simple",
  "widgets.openFormDialog": "Abrir el cuadro de diálogo de formulario",
  "widgets.follower": "Seguidor",
  "widgets.important": "Importante",
  "widgets.private": "Privado",
  "widgets.openLeft": "Abierto a la izquierda",
  "widgets.openRight": "Abierto a la derecha",
  "widgets.openTop": "Tapa abierta",
  "widgets.openBottom": "Parte inferior abierta",
  "widgets.selectTripDestination": "Seleccione el destino del viaje",
  "widgets.pinnedSubheaderList": "Lista de subcarpetas fijadas",
  "widgets.singleLineItem": "Artículo de línea única",
  "widgets.acceptTerms": "Aceptar los terminos",
  "widgets.optionA": "Opción A",
  "widgets.optionB": "Opción B",
  "widgets.optionC": "Opción C",
  "widgets.optionM": "Opción M",
  "widgets.optionN": "Opción N",
  "widgets.optionO": "Opción O",
  "widgets.customColor": "Color personalizado",
  "widgets.centeredTabs": "Fichas centradas",
  "widgets.multipleTabs": "Múltiples pestañas",
  "widgets.preventScrolledButtons": "Evitar botones desplazados",
  "widgets.browse": "Vistazo",
  "widgets.formValidate": "Formulario Validar",
  "widgets.code": "Código",
  "widgets.company": "Empresa",
  "widgets.price": "Precio",
  "widgets.change": "Cambio",
  "widgets.high": "Alto",
  "widgets.low": "Bajo",
  "widgets.volume": "Volumen",
  "widgets.personalDetails": "Detalles personales",
  "widgets.occupation": "Ocupación",
  "widgets.companyName": "nombre de empresa",
  "widgets.phoneNo": "Telefono no",
  "widgets.city": "Ciudad",
  "widgets.zipCode": "Código postal",
  "widgets.updateProfile": "Actualización del perfil",
  "widgets.reject": "Rechazar",
  "widgets.exportToExcel": "Exportar a Excel",
  "widgets.addNewUser": "Añadir nuevo usuario",
  "widgets.workWeek": "Semana de trabajo",
  "widgets.agenda": "Agenda",
  "widgets.conference": "Conferencia",
  "widgets.dailySales": "Ventas diarias",
  "widgets.today": "Hoy",
  "widgets.campaignPerformance": "Rendimiento de la campaña",
  "widgets.supportRequest": "Solicitud de soporte",
  "widgets.usersList": "Lista de usuarios",
  "widgets.lastWeek": "La semana pasada",
  "themeOptions.sidebarOverlay": "Superposición de barra lateral",
  "themeOptions.sidebarBackgroundImages":
    "Imágenes de fondo de la barra lateral",
  "themeOptions.sidebarImage": "Imagen de la barra lateral",
  "themeOptions.miniSidebar": "Mini barra lateral",
  "themeOptions.boxLayout": "Diseño de caja",
  "themeOptions.rtlLayout": "Rtl Layout",
  "themeOptions.darkMode": "Modo oscuro",
  "themeOptions.appSettings": "Ajustes de Aplicacion",
  "themeOptions.sidebarLight": "Ligero",
  "themeOptions.sidebarDark": "Oscuro",
  "button.cancel": "Cancelar",
  "button.add": "Añadir",
  "button.update": "Actualizar",
  "button.reply": "Respuesta",
  "button.delete": "Borrar",
  "button.yes": "Sí",
  "button.viewAll": "Ver todo",
  "button.like": "Me gusta",
  "button.assignNow": "Asignar ahora",
  "button.seeInsights": "Ver estadísticas",
  "sidebar.dateTimePicker": "Selector de fecha y hora",
  "components.summary": "Resumen",
  "hint.whatAreYouLookingFor": "Qué estás buscando",
  "components.yesterday": "Ayer",
  "components.last7Days": "últimos 7 días",
  "components.last1Month": "Último 1 mes",
  "components.last6Month": "Últimos 6 meses",
  "components.spaceUsed": "Espacio utilizado",
  "components.followers": "Seguidor",
  "components.trending": "Tendencia",
  "components.paid": "Pagado",
  "components.refunded": "Reembolsado",
  "components.done": "Hecho",
  "components.pending": "Pendiente",
  "components.cancelled": "Cancelado",
  "components.approve": "Aprobar",
  "components.week": "Semana",
  "components.month": "Mes",
  "components.year": "Año",
  "components.today": "Hoy",
  "componentes.popularity": "Popularidad",
  "components.email": "Correo electrónico",
  "components.drafts": "Borradores",
  "components.sent": "Enviado",
  "components.trash": "Papelera",
  "components.all": "Todos",
  "components.do": "Do",
  "components.title": "Título",
  "components.projectName": "Nombre del proyecto",
  "components.companyName": "Nombre de la compañía",
  "components.openAlert": "Alerta abierta",
  "components.slideInAlertDialog": "Dialogo de diapositivas en dialogo",
  "components.openFullScreenDialog":
    "Abrir cuadros de diálogo de pantalla completa",
  "components.basicChip": "Chip básico",
  "components.clickableChip": "Chip Clickable",
  "components.left": "Izquierda",
  "components.right": "Derecha",
  "components.expansionPanel1": "Panel de expansión 1",
  "components.expansionPanel2": "Panel de expansión 2",
  "components.generalSetting": "Configuración general",
  "components.advancedSettings": "Configuración avanzada",
  "components.firstName": "Nombre",
  "components.lastName": "Apellido",
  "components.occupation": "Ocupación",
  "components.phoneNo": "Teléfono No",
  "components.address": "Dirección",
  "components.city": "Ciudad",
  "components.state": "Estado",
  "components.zipCode": "código postal",
  "components.social Connection": "conexión social",
  "widgets.buyMore": "Comprar más",
  "widgets.trafficChannel": "Canal de tráfico",
  "widgets.stockExchange": "Bolsa de valores",
  "widgets.tweets": "Tweets",
  "widgets.ourLocations": "Nuestras ubicaciones",
  "widgets.sales": "Ventas",
  "widgets.to": "Para",
  "widgets.shipTo": "Enviar a",
  "widgets.description": "Descripción",
  "widgets.unitPrice": "Precio unitario",
  "widgets.total": "Total",
  "widgets.note": "Nota",
  "widgets.chipWithAvatar": "Chip con Avatar",
  "widgets.chipWithTextAvatar": "Chip con texto Avatar",
  "widgets.chipWithIconAvatar": "Chip con el icono Avatar",
  "widgets.customClickableChip": "Chip Clickable personalizado",
  "widgets.outlineChip": "Chip de esquema",
  "widgets.disableChip": "Desactivar Chip",
  "widgets.alertDialog": "Diálogo de alerta",
  "widgets.animatedSlideDialogs": "Diálogos de diapositiva animados",
  "widgets.fullScreenDialogs": "Cuadros de diálogo de pantalla completa",
  "widgets.formDialogs": "Diálogos de formulario",
  "widgets.simpleDialogs": "Cuadros de diálogo simples",
  "widgets.responsiveFullScreen": "Pantalla completa receptiva",
  "widgets.primary": "Director de escuela",
  "widgets.social": "social",
  "widgets.user": "Usuario",
  "widgets.admin": "Administrador",
  "widgets.permanentdrawer": "Cajón permanente",
  "widgets.persistentdrawer": "Cajón persistente",
  "widgets.swiches": "Swiches",
  "widgets.horizontalLinearAlternativeLabel":
    "Etiqueta alternativa horizontal lineal",
  "widgets.horizontalNonLinearAlternativeLabel":
    "etiqueta alternativa no lineal horizontal",
  "widgets.notifications": "Notificaciones",
  "widgets.basicAlert": "Alerta básica",
  "widgets.successAlert": "Alerta de éxito",
  "widgets.warningAlert": "Alerta de advertencia",
  "widgets.reactAutoSuggest": "reaccionar automáticamente sugerir",
  "widgets.components": "Componentes",
  "widgets.inputAdornments": "Adorements de entrada",
  "widgets.multiSelectList": "Lista de selección múltiple",
  "widgets.contextualColoredTable": "Tabla de colores Contexuales",
  "widgets.updateYourEmailAddress":
    "Actualiza tu dirección de correo electrónico",
  "widgets.selectADefaultAddress": "Seleccione una dirección predeterminada",
  "widgets.activity": "Actividad",
  "widgets.basicCalendar": "Calendario básico",
  "widgets.culturesCalendar": "Calendario de Culturas",
  "widgets.dragAndDropCalendar": "arrastrar y soltar calendario",
  "widgets.quillEditor": "Pluma Editor",
  "widgets.reactDND": "Reaccionar DND",
  "widgets.dragula": "Dragula",
  "button.acceptTerms": "Aceptar términos",
  "button.reject": "Reject",
  "button.addNew": "Agregar nuevo",
  "button.goToCampaign": "Ir a la campaña",
  "button.viewProfile": "Ver perfil",
  "button.sendMessage": "Enviar mensaje",
  "button.saveNow": "Guardar ahora",
  "button.pen": "bolígrafo",
  "button.search": "Buscar",
  "button.downloadPdfReport": "Descargar el informe PDF",
  "button.primary": "Principal",
  "button.secondary": "Secundario",
  "button.danger": "Peligro",
  "button.info": "Información",
  "button.success": "éxito",
  "button.warning": "Advertencia",
  "button.link": "Enlace",
  "button.smallButton": "Botón pequeño",
  "button.largeButton": "Botón grande",
  "button.blockLevelButton": "Botón de nivel de bloque",
  "button.primaryButton": "Botón primario",
  "button.button": "Botón",
  "button.save": "Guardar",
  "button.openMenu": "Abrir menú",
  "button.openWithFadeTransition": "Abrir con Descolorarse Transición",
  "button.openPopover": "Abrir Popover",
  "button.accept": "Aceptar",
  "button.click": "hacer clic",
  "button.complete": "Completo",
  "button.back": "Atrás",
  "button.next": "Siguiente",
  "button.completeStep": "Paso completo",
  "button.error": "Error",
  "button.writeNewMessage": "Escribir nuevo mensaje",
  "button.saveChanges": "Guardar cambios",
  "button.addNewUser": "Agregar nuevo usuario",
  "button.more": "Más",
  "hint.searchMailList": "Buscar lista dpe correo",
  "widgets.AcceptorrRejectWithin": "Aceptar o rechazar dentro",
  "widgets.quoteOfTheDay": "Cita del día",
  "widgets.updated10Minago": "Actualizado hace 10 min",
  "widgets.personalSchedule": "Horario personal",
  "widgets.activeUsers": "Usuarios activos",
  "widgets.totalRequest": "Solicitud total",
  "widgets.new": "Nuevo",
  "widgets.ShareWithFriends": "¡Compartir con amigos!",
  "widgets.helpToShareText":
    "¡Ayúdenos a difundir el mundo compartiendo nuestro sitio web con sus amigos y seguidores en las redes sociales!",
  "widgets.thisWeek": "Esta semana",
  "widgets.howWouldYouRateUs": "¿Cómo nos calificarías?",
  "widgets.booking": "Reserva",
  "widgets.confirmed": "Confirmado",
  "widgets.monthly": "mensual",
  "widgets.weekly": "semanal",
  "widgets.target": "Objetivo",
  "widgets.totalActiveUsers": "Total de usuarios activos",
  "sidebar.user": "Usuario",
  "sidebar.miscellaneous": "Diverso",
  "sidebar.promo": "Promoción",
  "themeOptions.themeColor": "Color del tema",
  "module.inbox": "Bandeja de entrada",
  "module.drafts": "Borradores",
  "module.sent": "Enviado",
  "module.trash": "Papelera",
  "module.spam": "Correo no deseado",
  "module.frontend": "Interfaz",
  "module.backend": "Backend",
  "module.api": "Api",
  "module.issue": "Problema",
  "components.emailPrefrences": "Preferencias de correo electrónico",
  "components.myProfile": "Mi perfil",
  "sidebar.gettingStarted": "Empezando",
  "widgets.deadline": "Fecha tope",
  "widgets.team": "Equipo",
  "widgets.projectManagement": "Gestión de proyectos",
  "widgets.latestPost": "Última publicación",
  "widgets.projectTaskManagement": "Project Task Management",
  "widgets.selectProject": "Seleccionar proyecto",
  "widgets.activityBoard": "Tablero de actividad",
  "widgets.checklist": "Checklist",
  "sidebar.shop": "tienda",
  "sidebar.cart": "Carro",
  "sidebar.checkout": "Revisa",
  "components.product": "Producto",
  "components.quantity": "Cantidad",
  "components.totalPrice": "Precio total",
  "components.removeProduct": "Eliminar producto",
  "components.mobileNumber": "Número de teléfono móvil",
  "components.address2Optional": "Dirección 2 (Opcional)",
  "components.country": "País",
  "components.zip": "Cremallera",
  "components.saveContinue": "Guardar Continuar",
  "components.placeOrder": "Realizar pedido",
  "components.payment": "Pago",
  "components.billingAddress": "Dirección de Envio",
  "components.ShippingAddressText":
    "La dirección de envío es la misma que la dirección de facturación.",
  "components.CartEmptyText": "¡Su cesta está vacía!",
  "components.NoItemFound": "No se encontró ningún artículo",
  "components.goToShop": "Ir a la tienda",
  "components.cardNumber": "Número de tarjeta",
  "components.expiryDate": "Fecha de caducidad",
  "components.cvv": "CVV",
  "components.nameOnCard": "Nombre en la tarjeta",
  "components.confirmPayment": "Confirmar pago",
  "sidebar.saas": "SAAS",
  "sidebar.multiLevel": "Multi nivel",
  "sidebar.level1": "Nivel 1",
  "sidebar.level2": "Nivel 2",
  "sidebar.boxed": "En caja",
  "sidebar.news": "Noticias",
  "sidebar.extensions": "Extensiones",
  "sidebar.imageCropper": "Imagen Cropper",
  "sidebar.videoPlayer": "Reproductor de video",
  "sidebar.dropzone": "Zona de descenso",
  "widgets.baseConfig": "Configuración de base",
  "widgets.customControlBar": "Barra de control personalizado",
  "widgets.withDownloadButton": "Con el botón Descargar",
  "widgets.httpLiveStreaming": "HTTP Live Streaming",
  "widgets.keyboardShortcuts": "Atajos de teclado",
  "button.useDefaultImage": "Usar imagen predeterminada",
  "button.cropImage": "Delimitar imagen",
  "widgets.preview": "Avance",
  "widgets.croppedImage": "Imagen recortada",
  "sidebar.crm": "CRM",
  "widgets.transactionList": "lista de transacciones",
  "widgets.transferReport": "informe de transferencia",
  "widgets.expenseCategory": "categoría de gastos",
  "widgets.upcomingEvents": "Próximos Eventos",
  "widgets.OngoingProjects": "Proyectos en marcha",
  "widgets.ProjectStatus": "estado del proyecto",
  "widgets.LiveChatSupport": "soporte de chat en vivo",
  "sidebar.projects": "proyectos",
  "sidebar.projectDetail": "detalle del proyecto",
  "sidebar.clients": "clientela",
  "sidebar.reports": "informes",
};
