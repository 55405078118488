import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import { apiPost, apiGet, apiFormDataPost } from "Api/apiCommon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NotificationManager } from "react-notifications";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { FileUploader } from "react-drag-drop-files";
import { IMAGEURL } from "../../base";
import MatButton from "@material-ui/core/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import DialogContentText from "@mui/material/DialogContentText";
import "bootstrap-daterangepicker/daterangepicker.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const EditOthersReject = () => {
  const location = useLocation();
  const selectedData = location.state ? location.state.selectedData : null;

  const [fileData, setfileData] = useState([]);
  const [remarkFileData, setremarkFileData] = useState([]);
  const [supportiveDocs, setSupportiveDocs] = useState([]);
  const [dataEntryDocs, setDataEntryDocs] = useState([]);
  const [supportiveAppDocs, setsupportiveAppDocs] = useState([]);
  const [rowData, setrowData] = useState({});

  useEffect(() => {
    (async () => {
      // if (User.get_work_flow_statge_user.length > 1) {
      //   setSecondStage(parseInt(User.get_work_flow_statge_user[1].stageNo));
      // }
      try {
        let data = {
          workFlowRequestUID: selectedData.workFlowRequestUID,
        };
        const response = await apiPost("user/viewapproverstatus", data);
        console.log(response.data.data);
        setfileData(response.data.data?.upload_file);
        let sDocs = [];
        let dDocs = [];
        let SAPPDocs = [];
        if (response.data.data?.upload_file.length > 0) {
          response.data.data?.upload_file.forEach((item) => {
            if (item.dataEntryFileName) {
              dDocs.push(item);
            } else if (item.requestFileName) {
              sDocs.push(item);
            } else {
              SAPPDocs.push(item);
            }
          });
        }
        setSupportiveDocs(sDocs);
        setDataEntryDocs(dDocs);
        setsupportiveAppDocs(SAPPDocs);

        setremarkFileData(
          response.data.data?.remarks_file
            ? response.data.data?.remarks_file
            : []
        );
        setrowData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);

  function openImageInNewTab(imageUrl) {
    // Create a new tab with the image URL
    window.open(imageUrl, "_blank");
  }

  useEffect(() => {
    //console.log(selectedData);
    // Check if selectedData is not undefined and has data
    if (selectedData && Object.keys(selectedData).length > 0) {
      setFormData(selectedData);
    }
  }, [selectedData]);

  const docFileTypes = [
    "png",
    "PDF",
    "DOCX",
    "DOC",
    "xlsx",
    "xls",
    "csv",
    "zip",
    "eml",
    "txt",
    "xml",
    "ppt",
    "ott",
    "svg",
    "msg",
    "pst",
    "ost",
    "oft",
    "OLM",
    "emlx",
    "mbox",
    "ics",
  ];

  const history = useHistory();
  const [multipleFile, setmultipleFile] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    brandUID: "",
    brand: "",
    Remarks: "",
    description: "",
    statusDescription: "",
    requestNo: "",
    multipleUpload: [],
  });
  const [fDate, setFDate] = useState(new Date().toLocaleDateString("en-US"));
  const [tDate, settDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 30)).toLocaleDateString(
      "en-US"
    )
  );
  const userString = sessionStorage.getItem("bixware_user");
  const User = JSON.parse(userString);
  const userData = User.get_work_flow_statge_user;
  const [branduid, setBrandUid] = useState([]);
  const [category, setCategory] = useState([]);
  const [dcDsd, setDcDsd] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [fileIndex, setFileIndex] = useState(null);

  useEffect(() => {
    let usersData = userData.find(
      (item) => item.stageName === "Initiator" && item.workFlowName === "Others"
    );
    setCurrentUser(usersData);
    console.log(usersData);
    const fetchBrandUid = async () => {
      try {
        const response = await apiGet("user/getbrand");
        if (response && response.data && response.data.data) {
          setBrandUid(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBrandUid();

    const fetchCategory = async () => {
      try {
        const response = await apiGet("user/getcategorylist");
        if (response && response.data && response.data.data) {
          setCategory(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCategory();

    const fetchDcDsd = async () => {
      try {
        const response = await apiGet("user/getdcdsdlist");
        if (response && response.data && response.data.data) {
          setDcDsd(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDcDsd();
  }, []);

  const handleInputInteger = (e) => {
    const { name, value } = e.target;
    const validValue = value.replace(/[^0-9,]/g, "");
    setFormData({ ...formData, [name]: validValue });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleCatChange = (e) => {
    const { name, value } = e.target;
    let selectedCat = category.find(
      (item) => item.categoryUID === parseInt(e.target.value)
    );
    setFormData({
      ...formData,
      [name]: selectedCat.categoryName,
      ["categoryUID"]: value,
    });
  };
  const handleBrandChange = (e) => {
    const { name, value } = e.target;
    let selectedBrand = branduid.find(
      (item) => item.brandUID === parseInt(e.target.value)
    );
    console.log(selectedBrand);
    setFormData({
      ...formData,
      [name]: selectedBrand.brandName,
      ["brandUID"]: value,
    });
  };

  const handledcDsdChange = (e) => {
    const { name, value } = e.target;
    let selectedDcDsd = dcDsd.find(
      (item) => item.dcdsdUID === parseInt(e.target.value)
    );
    setFormData({
      ...formData,
      [name]: selectedDcDsd.Name,
      ["dcDsdUID"]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.multipleUpload = multipleFile;
    // console.log(formData);
    setLoading(true);
    const form_data = new FormData();
    form_data.append("userUID", currentUser.userUID);
    form_data.append("businessUnitUID", currentUser.businessUnitUID);
    form_data.append("businessUnitName", currentUser.businessUnitName);
    form_data.append("userName", currentUser.userName);
    form_data.append("userEmail", currentUser.userEmail);
    form_data.append("approveID", currentUser.approveID);
    form_data.append("approveName", currentUser.approveName);
    form_data.append("rejectID", currentUser.rejectID);
    form_data.append("rejectName", currentUser.rejectName);
    form_data.append("stageName", currentUser.stageName);
    form_data.append("stageNo", currentUser.stageNo);
    form_data.append("workFlowName", currentUser.workFlowName);
    form_data.append("workFlowUID", currentUser.workFlowUID);
    form_data.append("workFlowStageUID", currentUser.workFlowStageUID);
    form_data.append("workFlowStageName", currentUser.stageName);

    form_data.append("requestNo", formData.requestNo);
    form_data.append("brandUID", formData.brandUID);
    form_data.append("brand", formData.brand);
    form_data.append("description", formData.description);
    form_data.append("Remarks", formData.Remarks);
    form_data.append("workFlowRequestUID", selectedData.workFlowRequestUID);
    form_data.append("statusDescription", formData.statusDescription);
    for (let i = 0; i < multipleFile.length; i++) {
      form_data.append(`multipleUpload[]`, multipleFile[i]);
    }
    if (formData.statusDescription === "Approved") {
      try {
        const response = await apiFormDataPost(
          "user/sfupdateothers",
          form_data
        );
        if (response) {
          setSuccess(true);
          setLoading(false);
        } else {
          console.error("Error adding role:", response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during API call:", error);
        setLoading(false);
      }
    } else {
      try {
        const response = await apiFormDataPost("user/sfrequestdrop", form_data);
        if (response) {
          setSuccess(true);
          setLoading(false);
        } else {
          console.error("Error adding role:", response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during API call:", error);
        setLoading(false);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      brandUID: "",
      brand: "",
      Remarks: "",
      description: "",
      multipleUpload: [],
    });
    history.goBack();
  };

  const handleChange = (file) => {
    //console.log(file);
    setmultipleFile((prevState) => {
      const tempArr = [...prevState];
      Array.from(file).forEach((item) => {
        let duplicateFile = tempArr.find((df) => df.name === item.name);
        if (!duplicateFile) tempArr.push(item);
      });
      // console.log(tempArr);
      return tempArr;
    });
  };

  function handleEvent(event, picker) {
    setFDate(new Date(picker.startDate.format()).toLocaleDateString("en-GB"));
    settDate(new Date(picker.endDate.format()).toLocaleDateString("en-GB"));
    formData.fromDate = new Date(picker.startDate.format()).toLocaleDateString(
      "en-GB"
    );
    formData.toDate = new Date(picker.endDate.format()).toLocaleDateString(
      "en-GB"
    );
  }

  const onConfirm = () => {
    setSuccess(false);
    setLoading(true);
    history.push("/app/user/request-list");
  };

  function clearFile(data) {
    console.log(data);
    setmultipleFile((prevState) => {
      const tempArr = [...prevState];
      let fileIndex = tempArr.findIndex((item) => item.name === data.name);
      if (fileIndex !== -1) tempArr.splice(fileIndex, 1);
      return tempArr;
    });
  }
  const clearFileDocs = async (index) => {
    // setfileData((prevState) => {
    //   const tempArr = [...prevState];
    //   tempArr.splice(index, 1);
    //   console.log(tempArr);
    //   return tempArr;
    // });
    setOpen(true);
    setFileIndex(index);
    // handleCloseRequest(index);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function handleCloseRequest() {
    let arrayoffiles = [];
    fileData.map((q, i) => {
      if (i === fileIndex) {
        arrayoffiles.push(q);
      }
    });
    try {
      let data = {
        workFlowRequestUID: selectedData.workFlowRequestUID,
        workFlowFileUID: arrayoffiles[0].workFlowFileUID,
      };
      const response = await apiFormDataPost("user/sfremovefile", data);
      const updatedFileData = fileData.filter(
        (_, index) => index !== fileIndex
      );
      setfileData(updatedFileData);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error("Error during API call:", error);
      setLoading(false);
    }
  }
  return (
    <RctCollapsibleCard>
      {formData.statusDescription === "Approved" ? (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4
                  className="mt-0 header-title"
                  style={{ fontSize: "1.5rem" }}
                >
                  Edit Others Drop/Reinitiate
                </h4>
                <a
                  href="#"
                  onClick={(e) => history.goBack()}
                  style={{ margin: "1%", float: "right" }}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="vendorCode">
                      Request No{"  "}
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="requestNo"
                      id="requestNo"
                      placeholder="Enter Request No"
                      value={formData.requestNo}
                      // onChange={handleInputInteger}
                      required
                      disabled={isReadOnly}
                      style={{
                        backgroundColor: isReadOnly ? "#ebedf2" : "white",
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="brandUID">
                      Brand <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        name="brand"
                        value={formData.brandUID}
                        // onChange={handleBrandChange}
                        className="select2 form-control"
                        required
                        style={{
                          paddingRight: "30px",
                          backgroundColor: isReadOnly ? "#ebedf2" : "white",
                        }}
                        disabled={isReadOnly}
                      >
                        <option value="" disabled>
                          Select Brand
                        </option>
                        {branduid.map((item) => (
                          <option key={item.brandUID} value={item.brandUID}>
                            {item.brandName}
                          </option>
                        ))}
                      </select>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="Description">
                      Description{"  "}
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Enter Description"
                      value={formData.description}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="Remarks">
                      Remarks{"  "}
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="textarea"
                      name="Remarks"
                      id="Remarks"
                      placeholder="Enter Remarks"
                      value={formData.Remarks}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="p-20">
                <div className="form-group d-flex justify-content-between">
                  <label>Initiator Supportive Documents </label>
                  <span>:</span>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="p-20">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {supportiveDocs.length > 0 ? (
                    <>
                      {fileData.map((item, i) => {
                        return (
                          <span key={i}>
                            <MatButton
                              download={true}
                              style={
                                item.requestFileName === null
                                  ? { display: "none", width: "130px" }
                                  : { display: "block", width: "130px" }
                              }
                              variant="contained"
                              className="btn-info mr-10 mb-10 text-white btn-icon"
                              onClick={() =>
                                openImageInNewTab(
                                  `${IMAGEURL}uploads/tickets/${item.requestFileName}`
                                )
                              }
                            >
                              File{i + 1} &nbsp;
                              <i className="zmdi zmdi-inbox"></i>&nbsp;
                              <svg
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearFileDocs(i);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                viewBox="0 0 14 14"
                              >
                                <path
                                  fill="none"
                                  stroke="black"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M1 3.5h12m-10.5 0h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 0V3a2.5 2.5 0 1 1 5 0v.5m-4 3.001v4.002m3-4.002v4.002"
                                ></path>
                              </svg>
                              {/* <i
                                className="zmdi zmdi-inbox ti-close"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#464D69",
                                  color: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              
                              ></i> */}
                              {/* <i className="ti-close"></i> */}
                            </MatButton>
                          </span>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {supportiveAppDocs.length > 0 ? (
            <div className="row">
              <div className="col-md-3">
                <div className="p-20">
                  <div className="form-group d-flex justify-content-between">
                    <label>Approver Supportive Documents </label>
                    <span>:</span>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="p-20">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {fileData.map((item, i) => {
                      return (
                        <span key={i}>
                          <MatButton
                            style={
                              item.approverFileName === null
                                ? { display: "none", width: "130px" }
                                : { display: "block", width: "130px" }
                            }
                            variant="contained"
                            className="btn-info mr-10 mb-10 text-white btn-icon"
                            onClick={() =>
                              openImageInNewTab(
                                `${IMAGEURL}uploads/tickets/${item.approverFileName}`
                              )
                            }
                            download={true}
                          >
                            File{i + 1} &nbsp;
                            <i className="zmdi zmdi-inbox"></i>&nbsp;
                            <svg
                              onClick={(e) => {
                                e.stopPropagation();
                                clearFileDocs(i);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width={22}
                              height={22}
                              viewBox="0 0 14 14"
                            >
                              <path
                                fill="none"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M1 3.5h12m-10.5 0h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 0V3a2.5 2.5 0 1 1 5 0v.5m-4 3.001v4.002m3-4.002v4.002"
                              ></path>
                            </svg>
                          </MatButton>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {dataEntryDocs.length > 0 ? (
            <div className="row">
              <div className="col-md-3">
                <div className="p-20">
                  <div className="form-group d-flex justify-content-between">
                    <label>Data Entry Documents </label>
                    <span>:</span>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="p-20">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {fileData.map((item, i) => {
                      return (
                        <span key={i}>
                          <MatButton
                            style={
                              item.dataEntryFileName === null
                                ? { display: "none", width: "130px" }
                                : { display: "block", width: "130px" }
                            }
                            variant="contained"
                            className="btn-info mr-10 mb-10 text-white btn-icon"
                            onClick={() =>
                              openImageInNewTab(
                                `${IMAGEURL}uploads/tickets/${item.dataEntryFileName}`
                              )
                            }
                            download={true}
                          >
                            File{i + 1} &nbsp;
                            <i className="zmdi zmdi-inbox"></i>&nbsp;
                            <svg
                              onClick={(e) => {
                                e.stopPropagation();
                                clearFileDocs(i);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width={22}
                              height={22}
                              viewBox="0 0 14 14"
                            >
                              <path
                                fill="none"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M1 3.5h12m-10.5 0h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 0V3a2.5 2.5 0 1 1 5 0v.5m-4 3.001v4.002m3-4.002v4.002"
                              ></path>
                            </svg>
                          </MatButton>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0 header-title">Drop/Reinitiate Details</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="card mt-3">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">
                          Upload Files{"  "}
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <div className="dropify-wrapper">
                          <FileUploader
                            handleChange={handleChange}
                            name="file"
                            types={docFileTypes}
                            value={formData.multipleUpload}
                            multiple={true}
                            required
                          />
                        </div>
                        {multipleFile.length > 0 ? (
                          <>
                            {multipleFile.map((item, i) => {
                              return (
                                <div className="row" key={i}>
                                  <div className="col-6">
                                    <h6>{item.name} </h6>
                                  </div>
                                  <div className="col-2">
                                    <i
                                      className="ti-close"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => clearFile(item)}
                                    ></i>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="card mt-3">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">
                          Drop/Reinitiate{"  "}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <FormGroup>
                          <div style={{ position: "relative" }}>
                            <select
                              name="statusDescription"
                              value={formData.statusDescription}
                              onChange={handleInputChange}
                              className="select2 form-control"
                              required
                              style={{ paddingRight: "30px" }}
                            >
                              <option value="" disabled>
                                Select Drop/Reinitiate
                              </option>
                              <option value="Approved">Reinitiate</option>
                              <option value="Send Back">Drop</option>
                            </select>
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right mt-3">
            {loading ? (
              <Button color="danger" type="submit" disabled>
                <i
                  className="zmdi zmdi-spinner zmdi-hc-spin"
                  // style={{ fontSize: "1rem", marginRight: "5px" }}
                ></i>{" "}
                Submit
              </Button>
            ) : (
              <Button color="danger" type="submit">
                Submit
              </Button>
            )}
            &nbsp; &nbsp;
            <Button color="primary" type="button" onClick={resetForm}>
              Cancel
            </Button>
          </div>

          <div style={{ marginTop: "2%" }}></div>
        </Form>
      ) : (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4
                  className="mt-0 header-title"
                  style={{ fontSize: "1.5rem" }}
                >
                  Edit Others Drop/Reinitiate
                </h4>
                <a
                  href="#"
                  onClick={(e) => history.goBack()}
                  style={{ margin: "1%", float: "right" }}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="vendorCode">
                      Request No{"  "}
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="requestNo"
                      id="requestNo"
                      placeholder="Enter Request No"
                      value={formData.requestNo}
                      // onChange={handleInputInteger}
                      disabled={isReadOnly}
                      style={{
                        backgroundColor: isReadOnly ? "#ebedf2" : "white",
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="brandUID">
                      Brand <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        name="brand"
                        value={formData.brandUID}
                        // onChange={handleBrandChange}
                        className="select2 form-control"
                        style={{
                          paddingRight: "30px",
                          backgroundColor: isReadOnly ? "#ebedf2" : "white",
                        }}
                        disabled={isReadOnly}
                      >
                        <option value="" disabled>
                          Select Brand
                        </option>
                        {branduid.map((item) => (
                          <option key={item.brandUID} value={item.brandUID}>
                            {item.brandName}
                          </option>
                        ))}
                      </select>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="Description">
                      Description{"  "}
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Enter Description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="Remarks">
                      Remarks{"  "}
                      <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="textarea"
                      name="Remarks"
                      id="Remarks"
                      placeholder="Enter Remarks"
                      value={formData.Remarks}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="p-20">
                <div className="form-group d-flex justify-content-between">
                  <label>Initiator Supportive Documents </label>
                  <span>:</span>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="p-20">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {supportiveDocs.length > 0 ? (
                    <>
                      {fileData.map((item, i) => {
                        return (
                          <span key={i}>
                            <MatButton
                              download={true}
                              style={
                                item.requestFileName === null
                                  ? { display: "none", width: "130px" }
                                  : { display: "block", width: "130px" }
                              }
                              variant="contained"
                              className="btn-info mr-10 mb-10 text-white btn-icon"
                              onClick={() =>
                                openImageInNewTab(
                                  `${IMAGEURL}uploads/tickets/${item.requestFileName}`
                                )
                              }
                            >
                              File{i + 1} &nbsp;
                              <i className="zmdi zmdi-inbox"></i>&nbsp;
                              <svg
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearFileDocs(i);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                viewBox="0 0 14 14"
                              >
                                <path
                                  fill="none"
                                  stroke="black"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M1 3.5h12m-10.5 0h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 0V3a2.5 2.5 0 1 1 5 0v.5m-4 3.001v4.002m3-4.002v4.002"
                                ></path>
                              </svg>
                              {/* <i
                                className="zmdi zmdi-inbox ti-close"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#464D69",
                                  color: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              
                              ></i> */}
                              {/* <i className="ti-close"></i> */}
                            </MatButton>
                          </span>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {supportiveAppDocs.length > 0 ? (
            <div className="row">
              <div className="col-md-3">
                <div className="p-20">
                  <div className="form-group d-flex justify-content-between">
                    <label>Approver Supportive Documents </label>
                    <span>:</span>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="p-20">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {fileData.map((item, i) => {
                      return (
                        <span key={i}>
                          <MatButton
                            style={
                              item.approverFileName === null
                                ? { display: "none", width: "130px" }
                                : { display: "block", width: "130px" }
                            }
                            variant="contained"
                            className="btn-info mr-10 mb-10 text-white btn-icon"
                            onClick={() =>
                              openImageInNewTab(
                                `${IMAGEURL}uploads/tickets/${item.approverFileName}`
                              )
                            }
                            download={true}
                          >
                            File{i + 1} &nbsp;
                            <i className="zmdi zmdi-inbox"></i>&nbsp;
                            <svg
                              onClick={(e) => {
                                e.stopPropagation();
                                clearFileDocs(i);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width={22}
                              height={22}
                              viewBox="0 0 14 14"
                            >
                              <path
                                fill="none"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M1 3.5h12m-10.5 0h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 0V3a2.5 2.5 0 1 1 5 0v.5m-4 3.001v4.002m3-4.002v4.002"
                              ></path>
                            </svg>
                          </MatButton>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {dataEntryDocs.length > 0 ? (
            <div className="row">
              <div className="col-md-3">
                <div className="p-20">
                  <div className="form-group d-flex justify-content-between">
                    <label>Data Entry Documents </label>
                    <span>:</span>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="p-20">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {fileData.map((item, i) => {
                      return (
                        <span key={i}>
                          <MatButton
                            style={
                              item.dataEntryFileName === null
                                ? { display: "none", width: "130px" }
                                : { display: "block", width: "130px" }
                            }
                            variant="contained"
                            className="btn-info mr-10 mb-10 text-white btn-icon"
                            onClick={() =>
                              openImageInNewTab(
                                `${IMAGEURL}uploads/tickets/${item.dataEntryFileName}`
                              )
                            }
                            download={true}
                          >
                            File{i + 1} &nbsp;
                            <i className="zmdi zmdi-inbox"></i>&nbsp;
                            <svg
                              onClick={(e) => {
                                e.stopPropagation();
                                clearFileDocs(i);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width={22}
                              height={22}
                              viewBox="0 0 14 14"
                            >
                              <path
                                fill="none"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M1 3.5h12m-10.5 0h9v9a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1zm2 0V3a2.5 2.5 0 1 1 5 0v.5m-4 3.001v4.002m3-4.002v4.002"
                              ></path>
                            </svg>
                          </MatButton>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0 header-title">Drop/Reinitiate Details</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="card mt-3">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">
                          Upload Files{"  "}
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <div className="dropify-wrapper">
                          <FileUploader
                            handleChange={handleChange}
                            name="file"
                            types={docFileTypes}
                            value={formData.multipleUpload}
                            multiple={true}
                          />
                        </div>
                        {multipleFile.length > 0 ? (
                          <>
                            {multipleFile.map((item, i) => {
                              return (
                                <div className="row" key={i}>
                                  <div className="col-6">
                                    <h6>{item.name} </h6>
                                  </div>
                                  <div className="col-2">
                                    <i
                                      className="ti-close"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => clearFile(item)}
                                    ></i>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="card mt-3">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">
                          Drop/Reinitiate{"  "}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </h4>
                        <FormGroup>
                          <div style={{ position: "relative" }}>
                            <select
                              name="statusDescription"
                              value={formData.statusDescription}
                              onChange={handleInputChange}
                              className="select2 form-control"
                              required
                              style={{ paddingRight: "30px" }}
                            >
                              <option value="" disabled>
                                Select Drop/Reinitiate
                              </option>
                              <option value="Approved">Reinitiate</option>
                              <option value="Send Back">Drop</option>
                            </select>
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right mt-3">
            {loading ? (
              <Button color="danger" type="submit" disabled>
                <i
                  className="zmdi zmdi-spinner zmdi-hc-spin"
                  // style={{ fontSize: "1rem", marginRight: "5px" }}
                ></i>{" "}
                Submit
              </Button>
            ) : (
              <Button color="danger" type="submit">
                Submit
              </Button>
            )}
            &nbsp; &nbsp;
            <Button color="primary" type="button" onClick={resetForm}>
              Cancel
            </Button>
          </div>

          <div style={{ marginTop: "2%" }}></div>
        </Form>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".MuiDialog-paper": {
            maxWidth: "55rem !important",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <i
            className="zmdi zmdi-close-circle"
            onClick={handleClose}
            style={{ margin: "1%", float: "right" }}
          ></i>
          Confirmation
        </DialogTitle>
        <DialogContent fullWidth>
          <h4>Are you sure want to delete this file ?</h4>
          <div className="text-right">
            <Button onClick={handleCloseRequest}>
              {" "}
              <span style={{ color: "red" }}>OK</span>
            </Button>{" "}
            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </DialogContent>
        <DialogActions>
          {/* Additional dialog actions can be added here */}
        </DialogActions>
      </Dialog>

      {formData.statusDescription === "Approved" ? (
        <SweetAlert
          success
          show={success}
          title="Success"
          btnSize="sm"
          onConfirm={() => onConfirm()}
        >
          Others Updated Successfully!
        </SweetAlert>
      ) : (
        <SweetAlert
          success
          show={success}
          title="Success"
          btnSize="sm"
          onConfirm={() => onConfirm()}
        >
          Others Dropped Successfully!
        </SweetAlert>
      )}
    </RctCollapsibleCard>
  );
};

export default EditOthersReject;
