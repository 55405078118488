import React from "react";

import RctDefaultLayout from "../../Container/DefaultLayout";
import CRMLayout from "Container/CRMLayout";

const adminComponent = () => {
  return (
    <>
      <>
        <CRMLayout />
      </>
    </>
  );
};

export default adminComponent;

{
  /* <Switch>
<Route path="/corporate/admin/dashboard" component={NewsDashboard} />
<Route path="/corporate/admin/newly" component={NewRegProfile} />
<Route path="/corporate/admin/onboard" component={UserProfile} />
<Route
  path="/corporate/admin/newregistration"
  component={Newregistration}
/>
</Switch> */
}
