// /**
//  * Sign Up With Firebase
//  */
// import React, { useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Button, AppBar, Toolbar } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import { Form, FormGroup, Input } from "reactstrap";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import { apiPost, setLoggedInuser } from "../Api/apiCommon";
// import QueueAnim from "rc-queue-anim";
// import { Fab } from "@material-ui/core";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// // components
// import { SessionSlider } from "Components/Widgets";
// // app config
// import AppConfig from "Constants/AppConfig";
// import logo from "../Assets/img/sapphire-logo.png";
// // redux action
// import {
//   signupUserInFirebase,
//   signinUserWithFacebook,
//   signinUserWithGoogle,
//   signinUserWithGithub,
//   signinUserWithTwitter,
// } from "Store/Actions";
// import SweetAlert from "react-bootstrap-sweetalert";

// function AppSignUp(props) {
//   const [message, setMessage] = useState("");
//   const [isMsgShow, setIsMsgShow] = useState(false);
//   const [MsgType, setMsgType] = useState("alert alert-success");
//   const history = useHistory();
//   const loading = useSelector((state) => state.loading);
//   const [success, setSuccess] = useState(false);

//   const [emailMssage, setEmailMssage] = useState("");
//   const [emailErrorVisible, setEmailErrorVisible] = useState("none");
//   const [passwordMssage, setpasswordMssage] = useState(false);
//   const [passwordErrorVisible, setpasswordErrorVisible] = useState("none");
//   const [passwordConfrimMssage, setpasswordConfrimMssage] = useState(false);

//   const inputEmailRef = useRef(null);
//   const inputPasswordRef = useRef(null);
//   const inputConfirmPasswordRef = useRef(null);

//   /**
//    * On User Signup
//    */
//   const onUserSignUp = async (event) => {
//     //  if (email !== "" && password !== "") {
//     //    dispatch(signupUserInFirebase({ email, password }, props.history));
//     //  }
//     event.preventDefault();

//     const email = inputEmailRef.current.value;
//     const password = inputPasswordRef.current.value;
//     const password_confirmation = inputPasswordRef.current.value;

//     // Client-side validation
//     if (!email) {
//       setEmailErrorVisible("block");
//       setEmailMssage("Please enter an email.");
//       return;
//     }

//     if (!password) {
//       setpasswordErrorVisible("block");
//       setpasswordMssage("Please enter a password.");
//       return;
//     }
//     if (!password_confirmation) {
//       setpasswordErrorVisible("block");
//       setpasswordConfrimMssage("Please enter a Confirm password.");
//       return;
//     }

//     try {
//       // API call for login
//       const response = await apiPost("/reset-password", {
//         email: email,
//         password: password,
//         password_confirmation: password_confirmation,
//       });

//       // if (response) {
//       //   if (response.data.message === "Success") {
//       //     if (response.data.data.User) {
//       //       setLoggedInuser(response.data.data.User, response.data.data.token);

//       //       if (response.data.data.User.roleUID === "1") {
//       //         history.push("/app/crm/user");
//       //       } else if (response.data.data.User.businessUnitUID === "7") {
//       //         history.push("/app/user/view-completed-reports");
//       //       } else {
//       //         let workflowUsers =
//       //           response.data.data.User.get_work_flow_statge_user;
//       //         let initiator = false;
//       //         let approval = false;
//       //         let dataEntry = false;
//       //         workflowUsers.forEach((item, i) => {
//       //           if (parseInt(item.stageNo) === 1) {
//       //             initiator = true;
//       //           }
//       //           if (
//       //             parseInt(item.stageNo) <
//       //               parseInt(item.work_flow.noOfStage) - 1 &&
//       //             parseInt(item.stageNo) !== 1
//       //           ) {
//       //             approval = true;
//       //           }
//       //           if (
//       //             parseInt(item.stageNo) ===
//       //             parseInt(item.work_flow.noOfStage) - 1
//       //           ) {
//       //             dataEntry = true;
//       //           }
//       //         });
//       //         if (
//       //           initiator === true &&
//       //           approval !== true &&
//       //           dataEntry !== true
//       //         ) {
//       //           history.push("/app/user/workflow");
//       //         } else if (
//       //           initiator !== true &&
//       //           approval === true &&
//       //           dataEntry !== true
//       //         ) {
//       //           history.push("/app/user/approvallist");
//       //         } else if (
//       //           initiator !== true &&
//       //           approval !== true &&
//       //           dataEntry === true
//       //         ) {
//       //           history.push("/app/user/dataentrylist");
//       //         }
//       //       }
//       //     } else {
//       //       setMsgType("alert alert-danger");
//       //       setMessage(response.data.data.error);
//       //       setIsMsgShow(true);
//       //     }
//       //   }
//       // } else {
//       //   setMsgType("alert alert-danger");
//       //   setMessage(response.data.data.error);
//       //   setIsMsgShow(true);
//       // }
//       if (response) {
//         setMsgType("alert alert-danger");
//         setMessage(response.data.data.error);
//         setIsMsgShow(true);
//         setSuccess(true);
//         history.push("/signin");
//       }
//     } catch (error) {
//       setMsgType("alert alert-danger");
//       setMessage("Something went wrong, Please try again later");
//       setIsMsgShow(true);
//       console.error("Error during login:", error);
//     }
//   };

//   const onConfirm = () => {
//     setSuccess(false);
//     // history.push("/app/crmuser/costlist");
//     // handleClicks();
//   };

//   const focusInput = (inputRef) => {
//     // Access the input element using the ref
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   };

//   return (
//     <QueueAnim type="bottom" duration={2000}>
//       <div className="rct-session-wrapper">
//         {loading && <LinearProgress />}
//         <AppBar position="static" className="session-header">
//           <Toolbar></Toolbar>
//         </AppBar>
//         <div className="session-inner-wrapper">
//           <div className="container">
//             <div className="row row-eq-height">
//               <div className="col-sm-7 col-md-7 col-lg-8">
//                 <div className="session-body text-center">
//                   <div className="session-head mb-15">
//                     <h2>Reset Password</h2>
//                   </div>
//                   <Form>
//                     <FormGroup className="has-wrapper">
//                       <Input
//                         type="mail"
//                         name="user-mail"
//                         id="user-mail"
//                         innerRef={inputEmailRef}
//                         className="has-input input-lg"
//                         placeholder="Enter Email Address"
//                         onChange={() => focusInput(inputEmailRef)}
//                         required
//                       />
//                       <span className="has-icon">
//                         <i className="ti-email"></i>
//                       </span>
//                       <div
//                         className="inputImportantRed"
//                         id="emailprint-error-msg-red"
//                         style={{ display: emailErrorVisible, color: "red" }}
//                       >
//                         {emailMssage}
//                       </div>
//                     </FormGroup>
//                     <FormGroup className="has-wrapper">
//                       <Input
//                         type="password"
//                         name="user-pwd"
//                         id="pwd"
//                         innerRef={inputPasswordRef}
//                         className="has-input input-lg"
//                         placeholder="Enter your Password"
//                         onChange={() => focusInput(inputPasswordRef)}
//                         onKeyDown={(e) => {
//                           if (e.key === "Enter") {
//                             document.getElementById("submitBtn").click();
//                           }
//                         }}
//                         required
//                       />
//                       <span className="has-icon">
//                         <i className="ti-lock"></i>
//                       </span>
//                       <div
//                         className="inputImportantRed"
//                         id="emailprint-error-msg-red"
//                         style={{ display: passwordErrorVisible, color: "red" }}
//                       >
//                         {passwordMssage}
//                       </div>
//                     </FormGroup>
//                     <FormGroup className="has-wrapper">
//                       <Input
//                         type="password_confirmation"
//                         name="user-pwd"
//                         id="pwd"
//                         innerRef={inputConfirmPasswordRef}
//                         className="has-input input-lg"
//                         placeholder="Enter your Confirm Password"
//                         onChange={() => focusInput(inputConfirmPasswordRef)}
//                         onKeyDown={(e) => {
//                           if (e.key === "Enter") {
//                             document.getElementById("submitBtn").click();
//                           }
//                         }}
//                         required
//                       />
//                       <span className="has-icon">
//                         <i className="ti-lock"></i>
//                       </span>
//                       <div
//                         className="inputImportantRed"
//                         id="emailprint-error-msg-red"
//                         style={{ display: passwordErrorVisible, color: "red" }}
//                       >
//                         {passwordConfrimMssage}
//                       </div>
//                     </FormGroup>
//                     <FormGroup className="mb-15">
//                       <Button
//                         className="btn-info text-white btn-block w-100"
//                         variant="contained"
//                         size="large"
//                         onClick={onUserSignUp}
//                       >
//                         Reset Password
//                       </Button>
//                     </FormGroup>
//                   </Form>
//                 </div>
//               </div>
//               <div className="col-sm-5 col-md-5 col-lg-4">
//                 <SessionSlider />
//               </div>
//             </div>
//           </div>
//           <SweetAlert
//             success
//             show={success}
//             title="Success"
//             btnSize="sm"
//             onConfirm={() => onConfirm()}
//           >
//             Password reset Successfully!
//           </SweetAlert>
//         </div>
//       </div>
//     </QueueAnim>
//   );
// }

// export default AppSignUp;

/**
 * Sign Up With Firebase
 */
// import React, { useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Button,
//   AppBar,
//   Toolbar,
//   LinearProgress,
//   Fab,
// } from "@material-ui/core";
// import { Link, useHistory } from "react-router-dom";
// import { Form, FormGroup, Input } from "reactstrap";
// import QueueAnim from "rc-queue-anim";
// import { apiPost, setLoggedInuser } from "../Api/apiCommon";
// import { SessionSlider } from "Components/Widgets";
// import AppConfig from "Constants/AppConfig";
// import logo from "../Assets/img/sapphire-logo.png";
// import {
//   signupUserInFirebase,
//   signinUserWithFacebook,
//   signinUserWithGoogle,
//   signinUserWithGithub,
//   signinUserWithTwitter,
// } from "Store/Actions";
// import SweetAlert from "react-bootstrap-sweetalert";

// function AppSignUp(props) {
//   const [message, setMessage] = useState("");
//   const [isMsgShow, setIsMsgShow] = useState(false);
//   const [MsgType, setMsgType] = useState("alert alert-success");
//   const history = useHistory();
//   const loading = useSelector((state) => state.loading);
//   const [success, setSuccess] = useState(false);

//   const [emailMssage, setEmailMssage] = useState("");
//   const [emailErrorVisible, setEmailErrorVisible] = useState("none");
//   const [passwordMssage, setpasswordMssage] = useState("");
//   const [passwordErrorVisible, setpasswordErrorVisible] = useState("none");
//   const [passwordConfirmMssage, setpasswordConfirmMssage] = useState("");
//   const [passwordConfirmErrorVisible, setpasswordConfirmErrorVisible] =
//     useState("none");

//   const inputEmailRef = useRef(null);
//   const inputPasswordRef = useRef(null);
//   const inputConfirmPasswordRef = useRef(null);

//   /**
//    * On User Signup
//    */
//   const onUserSignUp = async (event) => {
//     event.preventDefault();

//     const email = inputEmailRef.current.value;
//     const password = inputPasswordRef.current.value;
//     const password_confirmation = inputConfirmPasswordRef.current.value;

//     // Client-side validation
//     if (!email) {
//       setEmailErrorVisible("block");
//       setEmailMssage("Please enter an email.");
//       return;
//     } else {
//       setEmailErrorVisible("none");
//     }

//     if (!password) {
//       setpasswordErrorVisible("block");
//       setpasswordMssage("Please enter a password.");
//       return;
//     } else {
//       setpasswordErrorVisible("none");
//     }

//     if (!password_confirmation) {
//       setpasswordConfirmErrorVisible("block");
//       setpasswordConfirmMssage("Please enter a confirm password.");
//       return;
//     } else {
//       setpasswordConfirmErrorVisible("none");
//     }

//     if (password !== password_confirmation) {
//       setpasswordConfirmErrorVisible("block");
//       setpasswordConfirmMssage("Passwords do not match.");
//       return;
//     } else {
//       setpasswordConfirmErrorVisible("none");
//     }

//     try {
//       // API call for login
//       const response = await apiPost("/reset-password", {
//         email: email,
//         password: password,
//         password_confirmation: password_confirmation,
//       });

//       if (response) {
//         setSuccess(true);
//         setMsgType("alert alert-success");
//         setMessage("Password reset successfully!");
//         setIsMsgShow(true);
//         // history.push("/signin");
//       }
//     } catch (error) {
//       setMsgType("alert alert-danger");
//       setMessage("Something went wrong, Please try again later");
//       setIsMsgShow(true);
//       console.error("Error during password reset:", error);
//     }
//   };

//   const onConfirm = () => {
//     setSuccess(false);
//     history.push("/signin");
//   };

//   const focusInput = (inputRef) => {
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   };

//   return (
//     <QueueAnim type="bottom" duration={2000}>
//       <div className="rct-session-wrapper">
//         {loading && <LinearProgress />}
//         <AppBar position="static" className="session-header">
//           <Toolbar></Toolbar>
//         </AppBar>
//         <div className="session-inner-wrapper">
//           <div className="container">
//             <div className="row row-eq-height">
//               <div className="col-sm-7 col-md-7 col-lg-8">
//                 <div className="session-body text-center">
//                   <div className="session-head mb-15">
//                     <h2>Reset Password</h2>
//                   </div>
//                   <Form>
//                     <FormGroup className="has-wrapper">
//                       <Input
//                         type="email"
//                         name="user-mail"
//                         id="user-mail"
//                         innerRef={inputEmailRef}
//                         className="has-input input-lg"
//                         placeholder="Enter Email Address"
//                         onChange={() => focusInput(inputEmailRef)}
//                         required
//                       />
//                       <span className="has-icon">
//                         <i className="ti-email"></i>
//                       </span>
//                       <div
//                         className="inputImportantRed"
//                         id="emailprint-error-msg-red"
//                         style={{ display: emailErrorVisible, color: "red" }}
//                       >
//                         {emailMssage}
//                       </div>
//                     </FormGroup>
//                     <FormGroup className="has-wrapper">
//                       <Input
//                         type="password"
//                         name="user-pwd"
//                         id="pwd"
//                         innerRef={inputPasswordRef}
//                         className="has-input input-lg"
//                         placeholder="Enter your Password"
//                         onChange={() => focusInput(inputPasswordRef)}
//                         onKeyDown={(e) => {
//                           if (e.key === "Enter") {
//                             document.getElementById("submitBtn").click();
//                           }
//                         }}
//                         required
//                       />
//                       <span className="has-icon">
//                         <i className="ti-lock"></i>
//                       </span>
//                       <div
//                         className="inputImportantRed"
//                         id="password-error-msg-red"
//                         style={{ display: passwordErrorVisible, color: "red" }}
//                       >
//                         {passwordMssage}
//                       </div>
//                     </FormGroup>
//                     <FormGroup className="has-wrapper">
//                       <Input
//                         type="password"
//                         name="user-pwd-confirm"
//                         id="pwd-confirm"
//                         innerRef={inputConfirmPasswordRef}
//                         className="has-input input-lg"
//                         placeholder="Enter your Confirm Password"
//                         onChange={() => focusInput(inputConfirmPasswordRef)}
//                         onKeyDown={(e) => {
//                           if (e.key === "Enter") {
//                             document.getElementById("submitBtn").click();
//                           }
//                         }}
//                         required
//                       />
//                       <span className="has-icon">
//                         <i className="ti-lock"></i>
//                       </span>
//                       <div
//                         className="inputImportantRed"
//                         id="password-confirm-error-msg-red"
//                         style={{
//                           display: passwordConfirmErrorVisible,
//                           color: "red",
//                         }}
//                       >
//                         {passwordConfirmMssage}
//                       </div>
//                     </FormGroup>
//                     <FormGroup className="mb-15">
//                       <Button
//                         id="submitBtn"
//                         className="btn-info text-white btn-block w-100"
//                         variant="contained"
//                         size="large"
//                         onClick={onUserSignUp}
//                       >
//                         Reset Password
//                       </Button>
//                     </FormGroup>
//                   </Form>
//                 </div>
//               </div>
//               <div className="col-sm-5 col-md-5 col-lg-4">
//                 <SessionSlider />
//               </div>
//             </div>
//           </div>
//           <SweetAlert
//             success
//             show={success}
//             title="Success"
//             btnSize="sm"
//             onConfirm={onConfirm}
//           >
//             Password reset Successfully!
//           </SweetAlert>
//         </div>
//       </div>
//     </QueueAnim>
//   );
// }

// export default AppSignUp;

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  AppBar,
  Toolbar,
  LinearProgress,
  Fab,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import QueueAnim from "rc-queue-anim";
import { apiPost, setLoggedInuser } from "../Api/apiCommon";
import { SessionSlider } from "Components/Widgets";
import AppConfig from "Constants/AppConfig";
import logo from "../Assets/img/sapphire-logo.png";
import {
  signupUserInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
} from "Store/Actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function AppSignUp(props) {
  const [message, setMessage] = useState("");
  const [isMsgShow, setIsMsgShow] = useState(false);
  const [MsgType, setMsgType] = useState("alert alert-success");
  const history = useHistory();
  const location = useLocation();
  const loading = useSelector((state) => state.loading);
  const [success, setSuccess] = useState(false);

  const [emailMssage, setEmailMssage] = useState("");
  const [emailErrorVisible, setEmailErrorVisible] = useState("none");
  const [passwordMssage, setpasswordMssage] = useState("");
  const [passwordErrorVisible, setpasswordErrorVisible] = useState("none");
  const [passwordConfirmMssage, setpasswordConfirmMssage] = useState("");
  const [passwordConfirmErrorVisible, setpasswordConfirmErrorVisible] =
    useState("none");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const inputEmailRef = useRef(null);
  const inputPasswordRef = useRef(null);
  const inputConfirmPasswordRef = useRef(null);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const [url, setUrl] = useState(window.location.href);
  const [token, setToken] = useState("");

  useEffect(() => {
    // Extract the token from the URL when the component mounts or the URL changes
    const extractedToken = url.split("/reset/")[1];
    setToken(extractedToken);
  }, [url]);

  const refreshUrl = () => {
    setUrl(window.location.href);
  };

  /**
   * On User Signup
   */
  const onUserSignUp = async (event) => {
    // const url = window.location.href;
    // const token = url.split("/reset/")[1];

    if (!token) {
      setIsMsgShow(true);
      setMsgType("alert alert-danger");
      setMessage(
        "Invalid or expired token. Please request a new password reset link."
      );
      return;
    }
    event.preventDefault();

    // const email = inputEmailRef.current.value;
    const password = inputPasswordRef.current.value;
    const password_confirmation = inputConfirmPasswordRef.current.value;

    // Client-side validation
    // if (!email) {
    //   setEmailErrorVisible("block");
    //   setEmailMssage("Please enter an email.");
    //   return;
    // } else {
    //   setEmailErrorVisible("none");
    // }

    if (!password) {
      setpasswordErrorVisible("block");
      setpasswordMssage("Please enter a password.");
      return;
    } else {
      setpasswordErrorVisible("none");
    }

    if (!password_confirmation) {
      setpasswordConfirmErrorVisible("block");
      setpasswordConfirmMssage("Please enter a confirm password.");
      return;
    } else {
      setpasswordConfirmErrorVisible("none");
    }

    if (password !== password_confirmation) {
      setpasswordConfirmErrorVisible("block");
      setpasswordConfirmMssage("Passwords do not match.");
      return;
    } else {
      setpasswordConfirmErrorVisible("none");
    }

    try {
      // API call for login
      const response = await apiPost("/reset-password", {
        // email: email,
        password: password,
        password_confirmation: password_confirmation,
        token: token,
      });

      if (response) {
        setSuccess(true);
        setMsgType("alert alert-success");
        setMessage("Password reset successfully!");
        setIsMsgShow(true);
        // history.push("/signin");
      }
    } catch (error) {
      setMsgType("alert alert-danger");
      setMessage("Something went wrong, Please try again later");
      setIsMsgShow(true);
      console.error("Error during password reset:", error);
    }
  };

  const onConfirm = () => {
    setSuccess(false);
    history.push("/signin");
  };

  const focusInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}
        <AppBar position="static" className="session-header">
          <Toolbar></Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-7 col-md-7 col-lg-8">
                <div className="session-body text-center">
                  <div className="session-head mb-15">
                    <h2>Reset Password</h2>
                  </div>
                  <Form>
                    {/* <FormGroup className="has-wrapper">
                      <Input
                        type="email"
                        name="user-mail"
                        id="user-mail"
                        innerRef={inputEmailRef}
                        className="has-input input-lg"
                        placeholder="Enter Email Address"
                        onChange={() => focusInput(inputEmailRef)}
                        required
                      />
                      <span className="has-icon">
                        <i className="ti-email"></i>
                      </span>
                      <div
                        className="inputImportantRed"
                        id="emailprint-error-msg-red"
                        style={{ display: emailErrorVisible, color: "red" }}
                      >
                        {emailMssage}
                      </div>
                    </FormGroup> */}
                    <FormGroup className="has-wrapper">
                      <Input
                        type={passwordVisible ? "text" : "password"}
                        name="user-pwd"
                        id="pwd"
                        innerRef={inputPasswordRef}
                        className="has-input input-lg"
                        placeholder="Enter your Password"
                        onChange={() => focusInput(inputPasswordRef)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submitBtn").click();
                          }
                        }}
                        required
                      />
                      <span
                        className="has-icon"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={passwordVisible ? faEyeSlash : faEye}
                        />
                      </span>
                      <div
                        className="inputImportantRed"
                        id="password-error-msg-red"
                        style={{ display: passwordErrorVisible, color: "red" }}
                      >
                        {passwordMssage}
                      </div>
                    </FormGroup>
                    <FormGroup className="has-wrapper">
                      <Input
                        type={confirmPasswordVisible ? "text" : "password"}
                        name="user-pwd-confirm"
                        id="pwd-confirm"
                        innerRef={inputConfirmPasswordRef}
                        className="has-input input-lg"
                        placeholder="Enter your Confirm Password"
                        onChange={() => focusInput(inputConfirmPasswordRef)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            document.getElementById("submitBtn").click();
                          }
                        }}
                        required
                      />
                      <span
                        className="has-icon"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={confirmPasswordVisible ? faEyeSlash : faEye}
                        />
                      </span>
                      <div
                        className="inputImportantRed"
                        id="password-confirm-error-msg-red"
                        style={{
                          display: passwordConfirmErrorVisible,
                          color: "red",
                        }}
                      >
                        {passwordConfirmMssage}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-15">
                      <Button
                        id="submitBtn"
                        className="btn-info text-white btn-block w-100"
                        variant="contained"
                        size="large"
                        onClick={onUserSignUp}
                      >
                        Reset Password
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              </div>
              <div className="col-sm-5 col-md-5 col-lg-4">
                <SessionSlider />
              </div>
            </div>
          </div>
          <SweetAlert
            success
            show={success}
            title="Success"
            btnSize="sm"
            onConfirm={onConfirm}
          >
            Password reset Successfully!
          </SweetAlert>
        </div>
      </div>
    </QueueAnim>
  );
}

export default AppSignUp;
